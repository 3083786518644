import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"


const Header = ({ siteTitle }) => (
  <header>

    <div>
      <h1 className="logo">
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
      <p className="about-link"><Link to="/about">About</Link></p>
      <p className="subscribe-link"><a href="/subscribe">&#8669; Subscribe &#8669;</a></p>
      <p className="support-link"><a href="/support"> &#8620; Support &#8620;</a></p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
